import React, { useEffect, useState } from 'react'
import FingerprintJS, { GetResult } from '@fingerprintjs/fingerprintjs-pro'

export function App() {
  const [result, setResult] = useState<GetResult | null>(null)
  const [err, setErr] = useState<Error | null>(null)

  useEffect(() => {
    FingerprintJS.load({
      apiKey: process.env.REACT_APP_SUBSCRIPTION_KEY || '',
      endpoint: '/fpjs-worker/visitorId',
      scriptUrlPattern: '/fpjs-worker/agent?apiKey=<apiKey>&version=<version>&loaderVersion=<loaderVersion>',
    })
      .then(fp => fp.get())
      .then(res => {
        setResult(res)
      })
      .catch(e => {
        setErr(e)
        throw e
      })
  }, [])

  return (
    <div>
      {err != null ? `Error! ${err.message}` : `Visitor Id is ${result?.visitorId}`}
      {err != null ? null :
        <div id='result'>
          <code>
            {JSON.stringify(result, null, 2)}
          </code>
        </div>}
    </div>
  )
}
